const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
};

const activityLevels = {
  week_5_more: 5,
  week_3_4: 4,
  week_1_2: 3,
  once_a_week: 2,
  dont_exercise_regularly: 1,
};

export const registerPayload = (code: string, quizAnswers: any) => {
  const userRTDBProperties = {
    weightHistory: {
      [getTodayDateString()]: quizAnswers?.current_weight?.kg,
    },
    userDetails: {
      isQuizComplete: true,
      code: code,
      startingWeight: quizAnswers?.current_weight?.kg,
      goalWeight: quizAnswers?.target_weight?.kg,
      gender: quizAnswers?.gender || 'female',
      height: quizAnswers?.height?.cm,
      activityLevel: activityLevels[quizAnswers?.exercise_during_week],
      age: quizAnswers?.age?.year,
      unitsOfMeasure: 'imperial',
    },
  };

  return userRTDBProperties;
};
